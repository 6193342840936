/**
 * Nosy Index About Page Template
 *
 * @export AboutPage
 */
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image as ChakraImage,
    LinkBox,
    LinkOverlay,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import { PortableText, toPlainText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import ForwardArrow from '../../components/foward-arrow/forward-arrow'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import Link from '../../components/link/link'
import { BlogRow, EventsRow } from '../../components/sections'
import SEO from '../../components/seo/seo'
import nosyMarkDoodleGif from '../../images/nosy-doodle.gif'
import { leadSerializer } from '../../serializers'
import { mapEdgesToNodes, pluckRandomNodes } from '../../utils/helpers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_ABOUT_PAGE_QUERY($id: String!) {
        sanityPage(id: { eq: $id }) {
            title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            slug {
                current
            }
            heading: _rawHeading(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            mainImage {
                ...ImageWithPreview
            }
        }
        allSanityPost(
            sort: { fields: publishedAt, order: DESC }
            filter: { _id: { regex: "/^(?!drafts).*/" } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    title
                    excerpt: _rawExcerpt
                    mainImage {
                        ...ImageWithPreview
                    }
                    slug {
                        current
                    }
                }
            }
        }
        allSanityLandingPage(
            sort: { fields: title, order: ASC }
            filter: { title: { in: ["Tech Drinks", "Rubbish Networking"] } }
        ) {
            edges {
                node {
                    id
                    title
                    slug {
                        current
                    }
                    mainImage {
                        ...ImageWithPreview
                    }
                    seo {
                        seoTitle
                        seoDescription
                    }
                }
            }
        }
        allSanityTeam(
            filter: { 
                isCurrent: {
                    eq: true
                }
            }
        ) {
            edges {
                node {
                    id
                    name
                    role
                    mainImage {
                        ...ImageWithPreview
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`

const AboutPage = ( props ) => {
    // console.log( '🦄 ~ file: about.js ~ line 12 ~ AboutPage ~ props', { props } )

    // Destructure props
    const {
        data: {
            sanityPage: {
                seo: { seoTitle = '', seoDescription = '' },
                heading = [],
                mainImage = {},
                body = []
            },
            allSanityPost = [],
            allSanityLandingPage = [],
            allSanityTeam = []
        }
    } = props

    // Map edges to node item
    const team = mapEdgesToNodes( allSanityTeam )
    const posts = mapEdgesToNodes( allSanityPost )
    const events = mapEdgesToNodes( allSanityLandingPage )

    // // Pluck 3 random team members
    const randomTeamMembers = pluckRandomNodes( team, 3 )

    const fullImageWidths = useBreakpointValue( {
        base: 479,
        sm: 767,
        md: 991,
        lg: 1279,
        xl: 1535,
        '2xl': 2560
    } )

    const teamImageWidths = useBreakpointValue( {
        base: 447,
        sm: 344,
        md: 267,
        lg: 320
    } )

    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />
            <Container maxWidth='6xl' mb={[20]} centerContent>
                <Center flexDirection='column' maxWidth='3xl'>
                    <Heading
                        as='h1'
                        maxWidth='5xl'
                        color='gray.100'
                        fontSize={['5xl', '4xl', '6xl', '7xl']}
                        textAlign='center'
                        mt={12}
                        mb={24}
                        fontWeight='extrabold'>
                        {toPlainText( heading )}
                    </Heading>
                </Center>
            </Container>

            <Flex
                minHeight={[null, '50vh']}
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={[20, 40]}
                position='relative'
                _after={{
                    content: '""',
                    position: 'absolute',
                    width: 'full',
                    height: 'full',
                    background:
                        'linear-gradient(90deg, rgba(0,3,9,1) 0%, rgba(0,0,0,0) 20%, rgba(15,15,15,0) 80%, rgba(0,3,9,1) 100%)'
                }}>
                <Image
                    imageData={mainImage}
                    loading='eager'
                    width={fullImageWidths}
                    sx={{ width: 'full' }} />
            </Flex>

            <Container maxWidth='3xl' mb={[20, 40]}>
                <PortableText 
                    value={body} 
                    components={leadSerializer} />
                <Button
                    as={Link}
                    to='/showreel/'
                    size='lg'
                    variant='brand-outline'>
                        Watch showreel
                </Button>
            </Container>

            <Container maxWidth='6xl' my={[20, 40]}>
                <SimpleGrid columns={[1,2]} spacing={8}>
                    <Stack direction='column' spacing={12} justifyContent='center'>
                        <Heading 
                            as='h4' 
                            fontSize={['3xl', '4xl', '5xl']}
                            color='gray.100'>
                            As a team, we never stop learning and evolving. We support each other without judgement. Encouraging creativity, free-speech and personal growth.
                        </Heading>
                        <Flex>
                            <Button
                                as={Link}
                                to='/team/'
                                size='lg'
                                variant='brand-outline'>
                                Meet the team
                            </Button>
                        </Flex>
                    </Stack>
                    <Grid 
                        templateColumns='repeat(6, 1fr)' 
                        templateRows='repeat(2, 1fr)'
                        gap={6}>
                        {randomTeamMembers.map( ( { node }, index ) => (
                            <GridItem 
                                colSpan={[6, 3]} 
                                rowSpan={1}
                                colStart={ () => {
                                    if ( index === 2 ) {
                                        return [0,3]
                                    }
                                }}>
                                <LinkBox key={node.id} height='min'>
                                    <Box
                                        position='relative'
                                        mt={() => {
                                            const marginMap = {
                                                0: 28,
                                                1: 0,
                                                2: 0
                                            }

                                            return [4, 8, marginMap[index]]
                                        }}
                                        role='group'>
                                        <Image
                                            imageData={node.mainImage}
                                            width={teamImageWidths}
                                            height={380}
                                            sx={{
                                                width: 'full'
                                            }} />
                                        <LinkOverlay
                                            as={Link}
                                            to={`/team/${node.slug.current}/`}>
                                            <Box
                                                px={8}
                                                pt={20}
                                                pb={4}
                                                visibility='hidden'
                                                transition='all .343s'
                                                width='full'
                                                bgGradient='linear(to-b, transparent, brand_grayscale.darker)'
                                                position='absolute'
                                                bottom={0}
                                                opacity={0}
                                                _groupHover={{
                                                    visibility: 'visible',
                                                    opacity: 1
                                                }}>
                                                <Stack
                                                    direction='column'
                                                    spacing={2}
                                                    opacity={0}
                                                    transform='translateY(31px)'
                                                    transition='all .343s'
                                                    transitionDelay='.25s'
                                                    _groupHover={{
                                                        transform: 'translateY(0)',
                                                        opacity: 1
                                                    }}>
                                                    <Heading
                                                        as='h4'
                                                        color='brand_secondary.500'
                                                        fontSize={[
                                                            'lg',
                                                            'xl',
                                                            '2xl'
                                                        ]}>
                                                        {node.name}
                                                    </Heading>
                                                    <Stack
                                                        direction='row'
                                                        justifyContent='space-between'
                                                        alignItems='center'>
                                                        <Text>{node.role}</Text>
                                                        <ForwardArrow
                                                            color='brand_secondary.500'
                                                            transition='all 0.343s'
                                                            width='14px'
                                                            height='16px' />
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </LinkOverlay>
                                    </Box>
                                </LinkBox>
                            </GridItem>
                        ) )}
                    </Grid>
                </SimpleGrid>
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                <Stack direction='column' spacing={8}>
                    <Center>
                        <ChakraImage
                            src={nosyMarkDoodleGif}
                            alt='Nosy Mark doodle'
                            boxSize={['140px', '160px', '180px']}
                            objectFit='cover'
                            my={10} />
                    </Center>
                    <Center>
                        <Heading
                            maxWidth='md'
                            as='h5'
                            fontSize={['3xl', '4xl', '5xl']}
                            textAlign='center'
                            color='gray.100'
                            fontFamily='Modelica-Regular'>
                            and of course,
                            <br /> we love{' '}
                            <Link
                                to='/work/'
                                color='brand_secondary.500'
                                _hover={{ color: 'brand_secondary.700' }}>
                                our work
                            </Link>
                        </Heading>
                    </Center>
                </Stack>
            </Container>

            <Container maxWidth='6xl' mb={[20, 40]}>
                <BlogRow posts={posts} />
            </Container>

            <Container maxWidth='6xl'>
                <EventsRow heading='Events' events={events} />
            </Container>
        </Layout>
    )
}

export default AboutPage
